<template>
    <v-container>
        <v-layout column class="panel-wrapper ma-5 pa-5" style="background-color: #fff">
            <v-flex xs12 style="text-align: center">
                <div>
                    <img src="./_source/processing.png" alt="Pending Client" style="width: 150px;height: 150px;">
                </div>
                <h1 class="my-3"
                    v-text="process==='1'?'5 Steps to Complete Your Order':'Under Verification'"></h1>
                <v-stepper alt-labels class="elevation-0" :value="process">
                    <v-stepper-header>
                        <v-stepper-step step="1" :complete="process>'1'">Completing</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="2" :complete="process>'2'">Ordering</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="3" :complete="process>'3'">Verifying</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="4" :complete="process>'4'">Paying</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="5">Deploying</v-stepper-step>
                    </v-stepper-header>
                </v-stepper>
                <!--<p>It may take 1-2 hours. Please check for your invoice later.</p>-->
                <div>
                    <p v-if="process==='3'">Your account is being verified. It usually takes 2 hours.</p>
                    <p style="text-align: left;display: inline-block" v-if="process==='1'">
                        step 1: Complete your billing information<br>
                        step 2: Confirm you order.<br>
                        Step 3: We will verify your billing account.<br>
                        Step 4: Make Payment.<br>
                        Step 5: Your application will be automatically deployed (in minutes).
                        </p>
                </div>
                <v-btn v-if="process==='3'" class="my-3 primary" to="/applications">Check My Application</v-btn>
                <template v-if="process==='1'">
                    <v-btn  class="my-3 text-none" color="blue darken-1" flat to="/">Later</v-btn>
                    <v-btn class="my-3 primary text-none" :to="`profile/account?return_url=${$route.query.return_url}`">Continue
                    </v-btn>
                </template>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: "processing",
        data() {
            return {
                process: ""
            }
        },
        created() {
            this.process = this.$route.query.processing
        }
    }
</script>


