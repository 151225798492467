var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-layout",
        {
          staticClass: "panel-wrapper ma-5 pa-5",
          staticStyle: { "background-color": "#fff" },
          attrs: { column: "" }
        },
        [
          _c(
            "v-flex",
            { staticStyle: { "text-align": "center" }, attrs: { xs12: "" } },
            [
              _c("div", [
                _c("img", {
                  staticStyle: { width: "150px", height: "150px" },
                  attrs: {
                    src: require("./_source/processing.png"),
                    alt: "Pending Client"
                  }
                })
              ]),
              _c("h1", {
                staticClass: "my-3",
                domProps: {
                  textContent: _vm._s(
                    _vm.process === "1"
                      ? "5 Steps to Complete Your Order"
                      : "Under Verification"
                  )
                }
              }),
              _c(
                "v-stepper",
                {
                  staticClass: "elevation-0",
                  attrs: { "alt-labels": "", value: _vm.process }
                },
                [
                  _c(
                    "v-stepper-header",
                    [
                      _c(
                        "v-stepper-step",
                        { attrs: { step: "1", complete: _vm.process > "1" } },
                        [_vm._v("Completing")]
                      ),
                      _c("v-divider"),
                      _c(
                        "v-stepper-step",
                        { attrs: { step: "2", complete: _vm.process > "2" } },
                        [_vm._v("Ordering")]
                      ),
                      _c("v-divider"),
                      _c(
                        "v-stepper-step",
                        { attrs: { step: "3", complete: _vm.process > "3" } },
                        [_vm._v("Verifying")]
                      ),
                      _c("v-divider"),
                      _c(
                        "v-stepper-step",
                        { attrs: { step: "4", complete: _vm.process > "4" } },
                        [_vm._v("Paying")]
                      ),
                      _c("v-divider"),
                      _c("v-stepper-step", { attrs: { step: "5" } }, [
                        _vm._v("Deploying")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", [
                _vm.process === "3"
                  ? _c("p", [
                      _vm._v(
                        "Your account is being verified. It usually takes 2 hours."
                      )
                    ])
                  : _vm._e(),
                _vm.process === "1"
                  ? _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "left",
                          display: "inline-block"
                        }
                      },
                      [
                        _vm._v(
                          "\n                    step 1: Complete your billing information"
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                    step 2: Confirm you order."
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                    Step 3: We will verify your billing account."
                        ),
                        _c("br"),
                        _vm._v("\n                    Step 4: Make Payment."),
                        _c("br"),
                        _vm._v(
                          "\n                    Step 5: Your application will be automatically deployed (in minutes).\n                    "
                        )
                      ]
                    )
                  : _vm._e()
              ]),
              _vm.process === "3"
                ? _c(
                    "v-btn",
                    {
                      staticClass: "my-3 primary",
                      attrs: { to: "/applications" }
                    },
                    [_vm._v("Check My Application")]
                  )
                : _vm._e(),
              _vm.process === "1"
                ? [
                    _c(
                      "v-btn",
                      {
                        staticClass: "my-3 text-none",
                        attrs: { color: "blue darken-1", flat: "", to: "/" }
                      },
                      [_vm._v("Later")]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "my-3 primary text-none",
                        attrs: {
                          to:
                            "profile/account?return_url=" +
                            _vm.$route.query.return_url
                        }
                      },
                      [_vm._v("Continue\n                ")]
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }